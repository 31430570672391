import { template as template_11ba6e07ddbe45c1aafb556cbbfed3b2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_11ba6e07ddbe45c1aafb556cbbfed3b2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
