import { template as template_3e352c05c17d4f058c3c4a81a3e93e53 } from "@ember/template-compiler";
import AnonymousSections from "./anonymous/sections";
import UserSections from "./user/sections";
const SidebarSections = template_3e352c05c17d4f058c3c4a81a3e93e53(`
  {{#if @currentUser}}
    <UserSections
      @collapsableSections={{@collapsableSections}}
      @panel={{@panel}}
      @hideApiSections={{@hideApiSections}}
    />
  {{else}}
    <AnonymousSections @collapsableSections={{@collapsableSections}} />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSections;
