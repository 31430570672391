import { template as template_82d5f7e07fce46899822e33ba050488a } from "@ember/template-compiler";
const FKLabel = template_82d5f7e07fce46899822e33ba050488a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
